import React, { useContext } from "react";
import PropTypes from "prop-types";
import AwesomeSlider from "react-awesome-slider";
import useTranslations from "hooks/useTranslations";
import LocalizedLink from "components/localizedLink";
import { rhythm, scale } from "utils/typography";
import { IoMdPaper } from "react-icons/io";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import { mq } from "utils/helper";
import "style/react-awesome-slider.scss";
import { getSrc } from "gatsby-plugin-image";

function Slider({ items }) {
  const tr = useTranslations();
  const { colors } = useContext(ThemeContext);
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        " & div": {
          zIndex: 2,
        },
        " & img": {
          opacity: 0.4,
        },
      }}
    >
      <AwesomeSlider play={false} cancelOnInteraction={false} interval={1000}>
        {items.map((item) => (
          <div
            key={item.id}
            data-alt={item.title}
            data-src={getSrc(item.image.localFile)}
            css={{
              direction: isRTL ? "rtl" : "ltr",
            }}
          >
            <h1
              css={{
                maxWidth: "800px",
                color: colors.light,
                direction: isRTL ? "rtl" : "ltr",
                transition: "0.25s",
                "& a": {
                  color: colors.light,
                },
                "& :hover": {
                  textDecoration: "underline",
                },
                [mq[1]]: {
                  ...scale(0.5),
                  margin: rhythm(1),
                },
              }}
            >
              <LocalizedLink
                to={item.url ? item.url : `investigations/${item.slug}`}
              >
                {isRTL
                  ? item.localizations.data
                      .flat()
                      .map((a) => a.attributes)
                      .map((t) => t.title)[0]
                  : item.title}
              </LocalizedLink>
            </h1>
            <div
              css={{
                borderBottom: `1px solid ${colors.primary}`,
                maxWidth: "90%",
                marginLeft: isRTL ? "auto" : 0,
                [mq[1]]: {
                  margin: rhythm(1),
                },
              }}
            ></div>
            <div>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  direction: isRTL ? "rtl" : "ltr",
                  marginTop: rhythm(0.3),
                  color: colors.light,
                  [mq[1]]: {
                    margin: rhythm(1),
                  },
                }}
              >
                <IoMdPaper />
                <small
                  css={{
                    marginLeft: isRTL ? 0 : rhythm(0.3),
                    marginRight: isRTL ? rhythm(0.3) : 0,
                    marginTop: rhythm(0.3),
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {tr(item.type)}
                </small>
              </div>
            </div>
          </div>
        ))}
      </AwesomeSlider>
    </div>
  );
}

Slider.propTypes = {
  items: PropTypes.array,
};

export default Slider;
