import React, { useContext } from "react";
import styled from "@emotion/styled";
import { rhythm, scale } from "utils/typography";
import useTranslations from "hooks/useTranslations";
import ActionButton from "components/ActionButton";
import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";
import { mq } from "utils/helper";

const Link = styled.a(({ colors }) => ({
  textTransform: "uppercase",
  border: `3px solid ${colors.dark}`,
  padding: "0.5rem 1rem 0.2rem 1rem",
  fontSize: "85%",
  fontWeight: 500,
  transition: "0.25s",
  ":hover, :active": {
    backgroundColor: colors.dark,
    color: colors.light,
  },
}));

function Supportus() {
  const tr = useTranslations();
  const { actionButtonLink, colors, homepageSupportButtonLink } =
    useContext(ThemeContext);
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        backgroundColor: colors.bg,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <div
        css={{
          maxWidth: "950px",
          display: "grid",
          gridTemplateColumns: "0.6fr 1.4fr",
          paddingTop: rhythm(4),
          paddingBottom: rhythm(2),
          [mq[2]]: {
            margin: `0 ${rhythm(1)}`,
          },
          [mq[1]]: {
            display: "block",
            padding: `${rhythm(0.5)} 0 ${rhythm(1.5)}`,
          },
        }}
      >
        <h2
          css={{
            margin: 0,
            [mq[1]]: {
              margin: `${rhythm(2)} ${rhythm(1)}`,
              marginLeft: isRTL ? "auto" : 0,
              marginRight: isRTL ? 0 : "auto",
            },
          }}
        >
          {tr("Support us")}
        </h2>
        <div>
          <p
            css={{
              ...scale(0.45),
              maxWidth: "670px",
              fontWeight: "bold",
              [mq[1]]: {
                ...scale(0.3),
                marginBottom: rhythm(2),
              },
            }}
          >
            {tr("index-page support")}
          </p>
          {!!homepageSupportButtonLink && (
            <ActionButton
              text={tr("config.homepage.support_button")}
              link={homepageSupportButtonLink}
            />
          )}
          {!!actionButtonLink && (
            <ActionButton
              text={tr("config.header.action_button")}
              link={actionButtonLink}
              style={{
                borderLeft: isRTL ? "auto" : "none",
                borderRight: isRTL ? "none" : "auto",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Supportus;
