import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { rhythm, scale } from "utils/typography";
import { mq } from "utils/helper";
import LocalizedLink from "components/localizedLink";
import useTranslations from "hooks/useTranslations";

import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";

const CategoryNameRow = styled.div(({ colors }) => ({
  display: "flex",
  justifyContent: "space-between",
  maxWidth: rhythm(47),
  margin: `0 auto`,
  paddingTop: rhythm(1),
  marginBottom: rhythm(1),
  textTransform: "uppercase",
  color: colors.primary,
  fontWeight: "500",
  [mq[0]]: {
    marginBottom: 0,
  },
}));

const H4 = styled.h4({
  ...scale(0.4),
  letterSpacing: "0.23px",
  fontWeight: 500,
  margin: 0,
  transition: "0.25s",
  ":hover, :active": {
    textDecoration: "underline",
  },
  [mq[0]]: {
    marginBottom: rhythm(1),
  },
});

const Small = styled.small({
  fontSize: "85%",
  letterSpacing: "0.88px",
  fontWeight: "bold",
  transition: "0.25s",
  ":hover, :active": {
    textDecoration: "underline",
  },
  [mq[0]]: {
    display: "none",
  },
});

const PostTitle = styled.h4((props) => ({
  ...scale(0.3),
  textTransform: "none",
  lineHeight: "1.7rem",
  marginTop: rhythm(1 / 2),
  position: "relative",
  fontWeight: "bold",
  transition: "0.25s",
  [mq[1]]: {
    marginBottom: rhythm(0.5),
  },
  "&::before": {
    position: "absolute",
    left: props.isRTL ? "0" : "-1.5rem",
    right: props.isRTL ? "-1.5rem" : "0",
    content: '"•"',
    color: props.colors.primary,
  },
  ":hover, :active": {
    textDecoration: "underline",
  },
}));

const PostDesc = styled.p({
  lineHeight: "1rem",
  [mq[1]]: {
    marginBottom: 0,
  },
});

const PostColumns = styled.div({
  display: "flex",
  justifyContent: "space-between",
  marginLeft: rhythm(1),
  paddingBottom: rhythm(1),
  ".update": {
    width: "30%",
    [mq[1]]: {
      width: "100%",
      marginBottom: rhythm(1.5),
    },
  },
  [mq[1]]: {
    display: "block",
    paddingBottom: 0,
  },
});

const PostDate = styled.small(({ colors }) => ({
  fontWeight: "bold",
  color: colors.primary,
}));

const Box = styled.div(({ colors }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  background: colors.light,
  boxShadow: "-5px 5px 15px 0 rgba(0,0,0,0.25)",
  maxWidth: "1100px",
  margin: "auto",
  padding: `${rhythm(1)} 0`,
  [mq[2]]: {
    margin: rhythm(1),
    padding: `${rhythm(0.15)} 0`,
  },
  [mq[0]]: {
    background: colors.light,
    paddingBottom: rhythm(1),
  },
}));

function PressCoverage({ latestPressUpdates }) {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        backgroundImage: "url(/assets/backgroundsmallpattern.png)",
        paddingTop: rhythm(4),
        paddingBottom: rhythm(4),
        direction: isRTL ? "rtl" : "ltr",
        "& .press-link": {
          color: colors.primary,
        },
        [mq[2]]: {
          paddingTop: rhythm(2),
          paddingBottom: rhythm(2),
        },
        [mq[1]]: {
          paddingTop: rhythm(1),
          paddingBottom: rhythm(1),
        },
      }}
    >
      <Box colors={colors}>
        <div
          css={{
            maxWidth: "950px",
            margin: "auto",
            [mq[2]]: {
              margin: rhythm(1),
            },
          }}
        >
          <CategoryNameRow colors={colors}>
            <H4>
              <LocalizedLink to="about/press/" className="press-link">
                {tr("Press Coverage")}
              </LocalizedLink>
            </H4>
            <Small>
              <LocalizedLink to="about/press/" className="press-link">
                {tr("Read More")} +
              </LocalizedLink>
            </Small>
          </CategoryNameRow>
          <PostColumns>
            {latestPressUpdates.map((post) => (
              <div className="update" key={post.id}>
                <a href={post.link} rel="noopener noreferrer" target="_blank">
                  <PostTitle isRTL={isRTL} colors={colors}>
                    {post.title}
                  </PostTitle>
                </a>
                <PostDesc>{post.desc}</PostDesc>
                <PostDate colors={colors}>{post.date}</PostDate>
              </div>
            ))}
          </PostColumns>
        </div>
      </Box>
    </div>
  );
}

PressCoverage.propTypes = {
  latestPressUpdates: PropTypes.array,
};

export default PressCoverage;
