import React, { useContext } from "react";
import { rhythm, scale } from "utils/typography";
import useTranslations from "hooks/useTranslations";
import LocalizedLink from "components/localizedLink";
import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";
import WorkflowDiagram from "components/WorkflowDiagram";
import { mq } from "utils/helper";

function HomepageWorkflow() {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  return (
    <>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: colors.light,
          maxWidth: "950px",
          margin: "auto",
          direction: isRTL ? "rtl" : "ltr",
          [mq[2]]: {
            margin: `0 ${rhythm(1)}`,
          },
        }}
      >
        <h2
          css={{
            alignSelf: "start",
            marginTop: rhythm(3),
            marginBottom: rhythm(2),
            [mq[2]]: {
              marginTop: rhythm(2),
            },
            [mq[1]]: {
              marginBottom: 0,
            },
          }}
        >
          {tr("How we do it")}
        </h2>
        <WorkflowDiagram
          labelStyle={{ marginTop: rhythm(1), marginBottom: 0 }}
        />
        <LocalizedLink
          to="about/methods-tools"
          css={{
            fontSize: "85%",
            letterSpacing: isRTL ? "normal" : "0.88px",
            fontWeight: 500,
            lineHeight: "16px",
            marginTop: rhythm(3),
            marginBottom: rhythm(3),
            border: "3px solid rgba(0,0,0,0.8)",
            padding: "0.5rem 1rem 0.2rem 1rem",
            transition: "0.25s",
            ":hover, :active": {
              backgroundColor: colors.dark,
              color: colors.light,
            },
            [mq[1]]: {
              margin: rhythm(1),
            },
          }}
        >
          {tr("Explore our methodology")}
        </LocalizedLink>
      </div>
    </>
  );
}

export default HomepageWorkflow;
