import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { LocaleContext } from "context/locale-context";
import { useStaticQuery, graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import useTranslations from 'hooks/useTranslations';


function SEO({ metadata }) {
  const locale = useContext(LocaleContext);
  const { description, title, image } = metadata;
  const tr = useTranslations();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const socialMediaImage =
    site.siteMetadata.siteUrl + (image && getSrc(image));
  return (
    <Helmet
      htmlAttributes={{
        locale
      }}
      title={title}
      titleTemplate={`%s | ${tr(site.siteMetadata.title)}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: socialMediaImage
        },
        {
          property: `twitter:image`,
          content: socialMediaImage
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: title
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]}
    />
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
