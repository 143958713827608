import React, { useContext } from "react";
import PropTypes from "prop-types";
import { rhythm, scale } from "utils/typography";
import useTranslations from "hooks/useTranslations";

import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";
import { mq } from "utils/helper";

function About() {
  const tr = useTranslations();
  const { colors } = useContext(ThemeContext);
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        backgroundImage: "url(/assets/homepage-about.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "16%",
        backgroundColor: colors.bg,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: rhythm(2),
        paddingBottom: rhythm(2),
        direction: isRTL ? "rtl" : "ltr",
        transform: isRTL ? "scaleX(-1)" : "none",
        filter: isRTL ? "FlipH" : "none",
        [mq[0]]: {
          paddingTop: rhythm(1),
          paddingBottom: rhythm(1),
          backgroundSize: "40%",
        },
      }}
    >
      <div
        css={{
          transform: isRTL ? "scaleX(-1)" : "none",
          filter: isRTL ? "FlipH" : "none",
          maxWidth: "950px",
          ...scale(0.45),
          fontWeight: "bold",
          whiteSpace: "pre-wrap",
          [mq[2]]: {
            margin: rhythm(1),
          },
          [mq[1]]: {
            ...scale(0.25),
            lineHeight: "2rem",
          },
        }}
      >
        {tr("config.homepage.about")}
      </div>
    </div>
  );
}

export default About;

About.propTypes = {
  intro: PropTypes.string,
};
