import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { set, lensProp } from "ramda";
import Layout from "components/layout";
import SEO from "components/seo";
import useTranslations from "hooks/useTranslations";
import { LocaleContext } from "context/locale-context";
import Slider from "components/homepage/homepage-slider";
import Workflow from "components/homepage/homepage-workflow";
import WhoWeAre from "components/homepage/homepage-whoweare";
import About from "components/homepage/homepage-about";
import Supportus from "components/homepage/homepage-supportus";
import PressCoverage from "components/homepage/homepage-press-coverage";

function Index({ data }) {
  const locale = useContext(LocaleContext); // used in Graphql query
  const tr = useTranslations();
  const latestInvestigation = data.latestInvestigation.edges[0]?.node;
  const latestDatabase = data.latestDatabase.edges[0]?.node;
  const latestPressUpdates = data.latestPressUpdates.edges.map((p) => p.node);
  const sliderItems = [
    latestInvestigation &&
      set(lensProp("type"), "investigation", latestInvestigation),
    latestDatabase && set(lensProp("type"), "database", latestDatabase),
  ];
  return (
    <Layout>
      <SEO metadata={{ title: tr("config.site.title") }} />
      <Slider items={sliderItems} />
      <About />
      <Workflow />
      <WhoWeAre />
      <Supportus />
      <PressCoverage latestPressUpdates={latestPressUpdates} />
    </Layout>
  );
}

Index.propTypes = {
  data: PropTypes.object,
};

export default Index;

export const pageQuery = graphql`
  query IndexPartials($locale: String!) {
    latestPressUpdates: allPressJson(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          link
          title
          date
        }
      }
    }
    latestInvestigation: allStrapiInvestigation(sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          desc
          image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          localizations {
            data {
              attributes {
                title
              }
            }
          }
        }
      }
    }
    latestDatabase: allStrapiDataset(sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          slug
          url
          title
          desc
          type
          image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          localizations {
            data {
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;
